import { Col, Button } from "react-bootstrap";
import React from "react";
import styled from "styled-components";
import { BiEdit, BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

const Container = styled.div`
  border-radius: 5px;
  background-color: white;
  //margin-bottom: 30px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  > div:first-child {
    width: 40%;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    background-size: cover;
  }
  > div:last-child {
    padding: 15px 20px;
    min-height: 150px;
  }
  .button-container {
    display: flex;
    flex-wrap: nowrap;
  }
  .button-spacing {
    padding: 5px;
  }
`;

const SmallCardContainer = styled(Container)`
  margin-bottom: 10px;
  > div:first-child {
    width: 40%;
  }
  > div:last-child {
    padding: 10px 15px;
    min-height: 100px;
  }
`;

const MediumCardContainer = styled(Container)`
  margin-bottom: 30px;
  > div:first-child {
    width: 40%;
  }
  > div:last-child {
    padding: 15px 20px;
    min-height: 150px;
  }
`;

const LargeCardContainer = styled(Container)`
  margin-bottom: 50px;
  > div:first-child {
    width: 50%;
  }
  > div:last-child {
    padding: 20px 25px;
    min-height: 200px;
  }
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 18px;
    margin-bottom: 8px;
  }
  h4 {
    font-size: 20px;
  }
`;

const MenuItem = ({
  item,
  onEdit,
  onRemove,
  onOrder,
  color,
  currency,
  onUp,
  onDown,
  isFirst,
  isLast,
  size,
}) => {
  const cardProps = {
    item,
    onEdit,
    onRemove,
    onOrder,
    color,
    currency,
    onUp,
    onDown,
    isFirst,
    isLast,
  };

  if (size === "S") {
    return <SmallCard {...cardProps} />;
  } else if (size === "M") {
    return <MediumCard {...cardProps} />;
  } else {
    return <LargeCard {...cardProps} />;
  }
};

// SMALL CARD
function SmallCard({
  item,
  onEdit,
  onRemove,
  onOrder,
  color,
  currency,
  onUp,
  onDown,
  isFirst,
  isLast,
}) {
  return (
    <div className="small-card">
      <SmallCardContainer active={item.is_available}>
        {item.image && (
          <Col xs={2} style={{ backgroundImage: `url(${item.image})` }} />
        )}
        <Col
          xs={item.image ? 10 : 12}
          className="d-flex flex-column justify-content-between w-100"
        >
          <div>
            <h5 className="mb-0">
              <b>{item.name}</b>
            </h5>
            <div className="button-container">
              {onUp ? (
                <Button
                  variant="link"
                  className="button-spacing"
                  onClick={onUp}
                  disabled={isFirst}
                >
                  <BiUpArrowAlt size={20} color="black" />
                </Button>
              ) : null}
              {onDown ? (
                <Button
                  variant="link"
                  className="button-spacing"
                  onClick={onDown}
                  disabled={isLast}
                >
                  <BiDownArrowAlt size={20} color="black" />
                </Button>
              ) : null}
              {onEdit ? (
                <Button
                  variant="link"
                  onClick={onEdit}
                  className="button-spacing"
                >
                  <BiEdit size={20} />
                </Button>
              ) : null}

              {onRemove ? (
                <Button
                  variant="link"
                  onClick={onRemove}
                  className="button-spacing"
                >
                  <AiOutlineDelete size={20} color="red" />
                </Button>
              ) : null}
            </div>
            <p className="mb-2">{item.description}</p>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <h6 className="mb-0 text-standard">
              <b style={{ color }}>
                {item.price} {currency}
              </b>
            </h6>
            {onOrder ? (
              <Button
                variant="standard"
                style={{ backgroundColor: color }}
                className="mt-2"
                size="sm"
                onClick={() => onOrder(item)}
              >
                {!item.quantity ? "Add" : `+(${item.quantity})`}
              </Button>
            ) : null}
          </div>
        </Col>
      </SmallCardContainer>
    </div>
  );
}

// MEDUIM CARD
function MediumCard({
  item,
  onEdit,
  onRemove,
  onOrder,
  color,
  currency,
  onUp,
  onDown,
  isFirst,
  isLast,
}) {
  return (
    <div className="medium-card">
      <MediumCardContainer active={item.is_available}>
        {item.image && (
          <Col xs={5} style={{ backgroundImage: `url(${item.image})` }} />
        )}
        <Col
          xs={item.image ? 7 : 12}
          className="d-flex flex-column justify-content-between w-100"
        >
          <div>
            <div className="d-flex justify-content-between align-items-center mb-2 ">
              <h4 className="mb-0">
                <b>{item.name}</b>
              </h4>
              <div className="button-container">
                {onUp ? (
                  <Button
                    variant="link"
                    className="button-spacing"
                    onClick={onUp}
                    disabled={isFirst}
                  >
                    <BiUpArrowAlt size={20} color="black" />
                  </Button>
                ) : null}
                {onDown ? (
                  <Button
                    variant="link"
                    className="button-spacing"
                    onClick={onDown}
                    disabled={isLast}
                  >
                    <BiDownArrowAlt size={20} color="black" />
                  </Button>
                ) : null}
                {onEdit ? (
                  <Button
                    variant="link"
                    onClick={onEdit}
                    className="button-spacing"
                  >
                    <BiEdit size={20} />
                  </Button>
                ) : null}

                {onRemove ? (
                  <Button
                    variant="link"
                    onClick={onRemove}
                    className="button-spacing"
                  >
                    <AiOutlineDelete size={20} color="red" />
                  </Button>
                ) : null}
              </div>
            </div>
            <p className="mb-4">{item.description}</p>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <div>
              <h5 className="mb-0 text-standard">
                <b style={{ color }}>
                  {item.price} {currency}
                </b>
              </h5>

              {onOrder ? (
                <Button
                  variant="standard"
                  style={{ backgroundColor: color }}
                  className="mt-2"
                  size="sm"
                  onClick={() => onOrder(item)}
                >
                  {!item.quantity
                    ? "Add to shopping cart"
                    : `Add one more (${item.quantity})`}
                </Button>
              ) : null}
            </div>

            {!item.is_available ? (
              <small className="text-secondary">Not Available</small>
            ) : null}
          </div>
        </Col>
      </MediumCardContainer>
    </div>
  );
}

// LARGE CARD
function LargeCard({
  item,
  onEdit,
  onRemove,
  onOrder,
  color,
  currency,
  onUp,
  onDown,
  isFirst,
  isLast,
}) {
  return (
    <div className="large-card">
      <LargeCardContainer active={item.is_available}>
        {item.image && (
          <Col xs={6} style={{ backgroundImage: `url(${item.image})` }} />
        )}
        <Col
          xs={item.image ? 6 : 12}
          className="d-flex flex-column justify-content-between w-100"
        >
          <div>
            <h3 className="mb-0">
              <b>{item.name}</b>
            </h3>
            <div className="button-container">
              {onUp ? (
                <Button
                  variant="link"
                  className="button-spacing"
                  onClick={onUp}
                  disabled={isFirst}
                >
                  <BiUpArrowAlt size={20} color="black" />
                </Button>
              ) : null}
              {onDown ? (
                <Button
                  variant="link"
                  className="button-spacing"
                  onClick={onDown}
                  disabled={isLast}
                >
                  <BiDownArrowAlt size={20} color="black" />
                </Button>
              ) : null}
              {onEdit ? (
                <Button
                  variant="link"
                  onClick={onEdit}
                  className="button-spacing"
                >
                  <BiEdit size={20} />
                </Button>
              ) : null}

              {onRemove ? (
                <Button
                  variant="link"
                  onClick={onRemove}
                  className="button-spacing"
                >
                  <AiOutlineDelete size={20} color="red" />
                </Button>
              ) : null}
            </div>
            <p className="mb-4">{item.description}</p>
          </div>
          <div className="d-flex justify-content-between align-items-end">
            <h4 className="mb-0 text-standard">
              <b style={{ color }}>
                {item.price} {currency}
              </b>
            </h4>
            {onOrder ? (
              <Button
                variant="standard"
                style={{ backgroundColor: color }}
                className="mt-2"
                size="lg"
                onClick={() => onOrder(item)}
              >
                {!item.quantity
                  ? "Add to shopping cart"
                  : `Add one more (${item.quantity})`}
              </Button>
            ) : null}
          </div>
        </Col>
      </LargeCardContainer>
    </div>
  );
}

// const MenuItem = ({
//   item,
//   onEdit,
//   onRemove,
//   onOrder,
//   color,
//   currency,
//   onUp,
//   onDown,
//   isFirst,
//   isLast,
//   size,
// }) => (
//   <Container active={item.is_available}>
//     {item.image && (
//       <Col xs={5} style={{ backgroundImage: `url(${item.image})` }} />
//     )}
//     <Col
//       xs={item.image ? 7 : 12}
//       className="d-flex flex-column justify-content-between w-100"
//     >
//       <div>
//         <div className="d-flex justify-content-between align-items-center mb-2 ">
//           <h4 className="mb-0">
//             <b>{item.name}</b>
//           </h4>
//           <div className="button-container">
//             {onUp ? (
//               <Button
//                 variant="link"
//                 className="button-spacing"
//                 onClick={onUp}
//                 disabled={isFirst}
//               >
//                 <BiUpArrowAlt size={20} color="black" />
//               </Button>
//             ) : null}
//             {onDown ? (
//               <Button
//                 variant="link"
//                 className="button-spacing"
//                 onClick={onDown}
//                 disabled={isLast}
//               >
//                 <BiDownArrowAlt size={20} color="black" />
//               </Button>
//             ) : null}
//             {onEdit ? (
//               <Button
//                 variant="link"
//                 onClick={onEdit}
//                 className="button-spacing"
//               >
//                 <BiEdit size={20} />
//               </Button>
//             ) : null}
//
//             {onRemove ? (
//               <Button
//                 variant="link"
//                 onClick={onRemove}
//                 className="button-spacing"
//               >
//                 <AiOutlineDelete size={20} color="red" />
//               </Button>
//             ) : null}
//           </div>
//         </div>
//         <p className="mb-4">{item.description}</p>
//       </div>
//       <div className="d-flex justify-content-between align-items-end">
//         <div>
//           <h5 className="mb-0 text-standard">
//             <b style={{ color }}>
//               {item.price} {currency}
//             </b>
//           </h5>
//
//           {onOrder ? (
//             <Button
//               variant="standard"
//               style={{ backgroundColor: color }}
//               className="mt-2"
//               size="sm"
//               onClick={() => onOrder(item)}
//             >
//               {!item.quantity
//                 ? "Add to shopping cart"
//                 : `Add one more (${item.quantity})`}
//             </Button>
//           ) : null}
//         </div>
//
//         {!item.is_available ? (
//           <small className="text-secondary">Not Available</small>
//         ) : null}
//       </div>
//     </Col>
//   </Container>
// );

export default MenuItem;
