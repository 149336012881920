import React, { createContext, useState } from "react";

import {
  signIn as signInApi,
  signOut as signOutApi,
  register as registerApi,
  updateUser as updateUserApi,
  getUser as getUserApi,
  // getSession as getSessionApi,
} from "../apis";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const token_name =
    "sb-" +
    new URL(process.env.REACT_APP_SUPABASE_URL).hostname.replace(
      ".supabase.co",
      ""
    ) +
    "-auth-token";

  const [session, setSession] = useState(localStorage.getItem(token_name));
  const [loading, setLoading] = useState(false);

  const signIn = async (email, password, callback) => {
    setLoading(true);
    const {
      data: { session },
      error,
    } = await signInApi(email, password);

    if (error) {
      toast(error.message, { type: "error" });
    } else {
      // localStorage.setItem(
      //   "sb-" + process.env.REACT_APP_SUPABASE_REF_ID_ + "-auth-token",
      //   session
      // );
      setSession(session);

      callback();
    }

    setLoading(false);
  };

  const signOut = async () => {
    await signOutApi();
    // localStorage.removeItem("session");

    setSession(null);
  };

  const register = async (email, password, password1, callback) => {
    if (password !== password1) {
      toast("Passwords do not match", { type: "error" });
      return;
    }

    if (!isStrongPassword(password)) {
      toast("Password is not strong enough", { type: "error" });
      return;
    }

    setLoading(true);

    const { error } = await registerApi(email, password);

    if (error) {
      toast(error.message, { type: "error" });
    } else {
      toast("We have sent you an email with an activation link", {
        type: "success",
      });

      setLoading(false);
      callback();
    }
  };

  const isStrongPassword = (password) => {
    // The password must be at least 8 characters long, and include at least one lowercase letter, one uppercase letter, and one number.
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return regex.test(password);
  };

  const getUser = async (callback) => {
    setLoading(true);

    const { data } = await getUserApi();
    setSession(data);

    setLoading(false);
  };

  const userUpdate = async (data, callback) => {
    setLoading(true);
    console.log("---", 0, "userUpdate");
    await updateUserApi(data);

    setLoading(false);
  };

  const value = {
    session,
    loading,
    signIn,
    signOut,
    register,
    getUser,
    userUpdate,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthContext;
