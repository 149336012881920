import { IoMdArrowBack } from "react-icons/io";
import { BiCaretRight } from "react-icons/bi";

import { Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { ChromePicker } from "react-color";
import styled from "styled-components";
import { toast } from "react-toastify";

import { Howl } from "howler";

import { fetchPlace, updatePlace } from "../apis";
import MainLayout from "../layouts/MainLayout";
import MenuList from "../components/MenuList";

const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const MenuSettings = () => {
  const [place, setPlace] = useState({});
  const [font, setFont] = useState("");
  const [size, setSize] = useState("");
  const [color, setColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [currency, setCurrency] = useState("");
  const [grouping, setMenuGrouping] = useState("");
  const [notification, setNotification] = useState("");

  const params = useParams();
  const history = useHistory();

  const onBack = () => history.push(`/places/${params.id}`);

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id);
    // console.log(json);
    if (json) {
      setPlace(json);
      setFont(json.font);
      setColor(json.color);
      setCurrency(json.currency);
      setMenuGrouping(json.grouping);
      setNotification(json.notification);
      setSize(json.size);
    }
  };

  const onUpdatePlace = async () => {
    setLoading(true);
    const json = await updatePlace(place.id, {
      font,
      color,
      currency,
      grouping,
      notification,
      size,
    });
    if (json) {
      toast("New settings is updated", { type: "success" });
      setPlace(json);
      setLoading(false);
    }
  };

  const handlePlaySound = () => {
    if (notification) {
      const sound = new Howl({
        src: [require(`../sounds/${notification}`).default],
        volume: 0.5,
      });

      sound.play();
    }
  };

  useEffect(() => {
    onFetchPlace();
  });

  return (
    <MainLayout>
      <div className="d-flex align-items-center mb-4">
        <Button variant="link" onClick={onBack}>
          <IoMdArrowBack size={25} color="black" />
        </Button>
        <h3 className="mb-0 mr-2 ml-2">Menu Settings</h3>
      </div>

      <Row>
        {/* LEFT SIDE */}
        <Col md={4}>
          <Panel>
            <Form.Group>
              <Form.Label>Font</Form.Label>
              <Form.Control
                as="select"
                value={font}
                onChange={(e) => setFont(e.target.value)}
              >
                <option>Lato</option>
                <option>Teko</option>
                <option>Lobster</option>
                <option>Caveat</option>
                <option>Indie Flower</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Size</Form.Label>
              <Form.Control
                as="select"
                value={size}
                onChange={(e) => setSize(e.target.value)}
              >
                <option value="S">Small</option>
                <option value="M">Medium</option>
                <option value="L">Large</option>
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Color</Form.Label>
              <ChromePicker
                color={color}
                onChange={(value) => setColor(value.hex)}
                disableAlpha
                width="100%"
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Currency</Form.Label>
              <Form.Control
                as="select"
                value={currency}
                onChange={(e) => setCurrency(e.target.value)}
              >
                <option value="€">€ (EUR)</option>
                <option value="$">$ (USD)</option>
                <option value="£">£ (GBP)</option>
              </Form.Control>
            </Form.Group>

            {/*SOUND NOTIFICATION*/}

            <Form.Group>
              <Form.Label>Sound notification</Form.Label>
              <InputGroup className="mb-3">
                <Button
                  variant="outline-secondary"
                  id="button-addon2"
                  onClick={handlePlaySound}
                >
                  <BiCaretRight size={20} />
                </Button>
                <Form.Control
                  as="select"
                  value={notification}
                  onChange={(e) => setNotification(e.target.value)}
                >
                  <option value=""></option>
                  <option value="bell.mp3">bell</option>
                  <option value="click.mp3">click</option>
                  <option value="ding.mp3">ding</option>
                  <option value="fanfare.mp3">fanfare</option>
                  <option value="request.mp3">request</option>
                  <option value="livechat.mp3">livechat</option>
                  <option value="incoming.mp3">incoming</option>
                  <option value="notification.mp3">notification</option>
                </Form.Control>
              </InputGroup>
            </Form.Group>

            {/*OTHER SETTINGS*/}

            <Form.Group>
              <Form.Label>Other settings</Form.Label>
              <Form.Check
                type="checkbox"
                label="Menu grouping?"
                checked={grouping}
                onChange={(e) => setMenuGrouping(e.target.checked)}
              />
            </Form.Group>

            <Button
              className="mt-4"
              variant="standard"
              block
              onClick={onUpdatePlace}
              disabled={loading}
            >
              Save Setings
            </Button>
          </Panel>
        </Col>

        {/* RIGHT SIDE */}
        <Col md={8}>
          <MenuList
            place={place}
            font={font}
            color={color}
            currency={currency}
            grouping={grouping}
            onOrder={() => []}
            size={size}
          />
        </Col>
      </Row>
    </MainLayout>
  );
};

export default MenuSettings;
