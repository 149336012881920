import { Button, Col, Form, Row, Card, Spinner } from "react-bootstrap";
import { React, useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import AuthContext from "../contexts/AuthContext";
import { toast } from "react-toastify";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory();
  const auth = useContext(AuthContext);
  const { email_activate } = useParams();

  useEffect(() => {
    // supabase.auth.onAuthStateChange()

    if (auth.session) {
      history.replace("/places");
    }
  });

  useEffect(() => {
    if (email_activate) {
      toast("Account successfully activated. Log in to the system", {
        type: "success",
      });

      setEmail(email_activate);
      history.replace("/login");
    }
  }, [email_activate, history]);

  const onClick = async () => {
    auth.signIn(email, password, () => history.replace("/places"));
  };

  return (
    <MainLayout>
      <Row className="justify-content-center">
        <Col lg={6} md={8}>
          <Card>
            <Card.Body>
              <h3 className="my-4 text-center">
                <b>Log in</b>
              </h3>

              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Enter Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Group>
              <div className="mt-4">
                <Button
                  variant="standard"
                  block
                  onClick={onClick}
                  disabled={auth.loading}
                >
                  {auth.loading ? (
                    <Spinner
                      variant="standard"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </div>
            </Card.Body>
            <div className="text-center mb-4 mt-2">
              <Card.Link href="/register">
                Don't have an account? Go Sign Up
              </Card.Link>
            </div>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Login;
