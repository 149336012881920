import { v4 as uuidv4 } from "uuid";
import { createClient } from "@supabase/supabase-js";

// SUPABASE
const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

// AUTH

export async function signIn(email, password) {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password,
  });

  return { data, error };
}

export async function register(email, password) {
  const { data, error } = await await supabase.auth.signUp({
    email,
    password,
  });

  return { data, error };
}

export async function signOut() {
  await supabase.auth.signOut();
}

// PLACES

export async function fetchPlaces() {
  const { data, error } = await supabase
    .from("place")
    .select("id, image, name");

  if (error) {
    console.log("api.js/fetchPlaces", error.message);
  }

  if (data) {
    return data;
  }
}

export async function fetchPlace(id) {
  const { data, error } = await supabase
    .from("place")
    .select()
    .eq("id", id)
    .limit(1);

  const { data: categories, error: categories_error } = await supabase
    .from("category")
    .select()
    .eq("place_id", id);

  const { data: menu_items, error: menu_items_error } = await supabase
    .from("menuitem")
    .select()
    .eq("place_id", id);

  if (error) {
    console.log("api.js/fetchPlace", error.message);
  }

  if (categories_error) {
    console.log("api.js/fetchPlace", error.message);
  }

  if (menu_items_error) {
    console.log("api.js/fetchPlace", error.message);
  }

  if (data) {
    const categoriesWithMenuItems = categories.map((category) => {
      return {
        ...category,
        menu_items: menu_items.filter(
          (menu_item) => menu_item.category_id === category.id
        ),
      };
    });

    const data2 = { ...data[0], categories: categoriesWithMenuItems };

    return data2;
  }
}

export async function addPlace(name, image) {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  const { data, error } = await supabase
    .from("place")
    .insert({
      name: name,
      image: image,
      owner_id: user.id,
    })
    .select();

  if (error) {
    console.log("api.js/addPlace", error.message);
  }

  if (data) {
    return data;
  }
}

export async function removePlace(id) {
  const { error } = await supabase.from("place").delete().eq("id", id);

  if (error) {
    console.log("api.js/removePlace", error.message);
  }
  return null;
}

export async function updatePlace(id, params) {
  const { data, error } = await supabase
    .from("place")
    .update(params)
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/updatePlace", error.message);
  }

  if (data) {
    return data[0];
  }
}

// IMAGES

export async function uploadImage(image) {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  const uniqueFileName =
    user.id + "/" + uuidv4() + "." + image.type.split("/")[1];
  const { data, error } = await supabase.storage
    .from("places")
    .upload(uniqueFileName, image);

  if (error) {
    throw error;
  }

  if (data) {
    // const { publicUrl } = await supabase.storage
    //   .from("places")
    //   .getPublicUrl(uniqueFileName);

    const publicUrl =
      process.env.REACT_APP_SUPABASE_URL +
      "/storage/v1/object/public/places/" +
      uniqueFileName;

    return { name: uniqueFileName, url: publicUrl };
  }
}

export async function removeImage(name) {
  const { data, error } = await supabase.storage.from("places").remove([name]);

  if (error) {
    console.log("api.js/uploadImage", error.message);
  }

  return data;
}

// CATEGORIES

export async function addCategory(name, place_id) {
  const { data, error } = await supabase
    .from("category")
    .insert({ name: name, place_id: place_id })
    .select();

  if (error) {
    console.log("api.js/addCategory", error.message);
  }

  if (data) {
    return data[0];
  }
}

export async function removeCategory(id) {
  const { error } = await supabase.from("category").delete().eq("id", id);

  if (error) {
    console.log("api.js/removeCategory", error.message);
  }
}

export async function updateCategory(id, newName) {
  const { data, error } = await supabase
    .from("category")
    .update({ name: newName })
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/updateCategory", error.message);
  }

  if (data) {
    return data[0];
  }
}

export async function updateCategoryPosition(id, newPosition) {
  const { data, error } = await supabase
    .from("category")
    .update({ position: newPosition })
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/updateCategoryPosition", error.message);
  }

  if (data) {
    return data[0];
  }
}

// ITEMS

export async function addMenuItems(params) {
  const { data, error } = await supabase
    .from("menuitem")
    .insert(params)
    .select();

  if (error) {
    console.log("api.js/addMenuItems", error.message);
  }

  if (data) {
    return data[0];
  }
}

export async function updateMenuItem(id, params) {
  const { data, error } = await supabase
    .from("menuitem")
    .update(params)
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/updateMenuItem", error.message);
  }

  if (data) {
    return data;
  }
}

export async function removeMenuItem(id) {
  const { error } = await supabase.from("menuitem").delete().eq("id", id);

  if (error) {
    console.log("api.js/removeMenuItem", error.message);
  }
}

export async function updateMenuItemPosition(id, newPosition) {
  const { data, error } = await supabase
    .from("menuitem")
    .update({ position: newPosition })
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/updateMenuItemPosition", error.message);
  }

  if (data) {
    return data;
  }
}

// ORDERS

export async function createUnpaymentOrder(params) {
  params.created_at = new Date();

  const { data, error } = await supabase.from("order").insert(params).select();

  if (error) {
    console.log("api.js/createUnpaymentOrder", error.message);
  }

  if (data) {
    data[0].success = true;
    return data[0];
  }
}

export async function fetchOrders(placeId) {
  const { data, error } = await supabase
    .from("order")
    .select()
    .eq("place_id", placeId);

  if (error) {
    console.log("api.js/fetchOrders", error.message);
  }

  if (data) {
    return data;
  }
}

export async function fetchOrdersCompleted(id) {
  const { data, error } = await supabase
    .from("order")
    .update({ status: "completed" })
    .eq("id", id)
    .select();

  if (error) {
    console.log("api.js/fetchOrdersCompleted", error.message);
  }

  if (data) {
    return data;
  }
}

// USERS

export async function getUser() {
  const {
    data: { user },
  } = await supabase.auth.getUser();

  return { user };
}

export async function getSession() {
  const { data, error } = await supabase.auth.getSession();

  if (error) {
    console.log("api.js/getSession", error.message);
  }

  return data;
}

export function updateUser(new_first_name, new_last_name, new_email) {
  // return request("/auth/users/me/", {
  //   data: {
  //     first_name: new_first_name,
  //     last_name: new_last_name,
  //     // email: new_email,
  //   },
  //   method: "PATCH",
  // });
}
