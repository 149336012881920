import { IoMdArrowBack } from "react-icons/io";
import { Row, Col, Button, Collapse, Card } from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import React, { useState, useEffect, useCallback } from "react";

import { Howl } from "howler";

import { fetchOrders, fetchOrdersCompleted, fetchPlace } from "../apis";

import MainLayout from "../layouts/MainLayout";
import Order from "../components/Order";

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [newOrder, setNewOrder] = useState(false);

  const [notification, setNotification] = useState("");

  const params = useParams();
  const history = useHistory();

  const onBack = () => history.push(`/places/${params.id}`);

  const onFetchPlace = useCallback(async () => {
    const json = await fetchPlace(params.id);
    if (json) {
      setNotification(json.notification);
    }
  }, [params.id]);
  const getTodayDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const onFetchOrders = useCallback(async () => {
    const json = await fetchOrders(params.id);
    if (json) {
      setOrders(json);
    }
  }, [params.id]);

  const onCompleteOrder = async (orderId) => {
    const json = await fetchOrdersCompleted(orderId);
    if (json) {
      onFetchOrders();
    }
  };

  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handlePlaySound = useCallback(() => {
    if (notification) {
      const sound = new Howl({
        src: [require(`../sounds/${notification}`).default],
        volume: 0.5,
      });
      sound.play();
    }
  }, [notification]);

  useEffect(() => {
    const newOrderExists = orders.some(
      (order) => order.status === "processing"
    );
    if (newOrderExists) {
      setNewOrder(true);
    }
  }, [orders]);

  useEffect(() => {
    if (newOrder) {
      handlePlaySound();
      setNewOrder(false);
    }
  }, [newOrder, handlePlaySound]);

  useEffect(() => {
    onFetchPlace();
    onFetchOrders();
    const interval = setInterval(() => {
      onFetchOrders();
    }, 5000);
    return () => clearInterval(interval);
  }, [onFetchOrders, onFetchPlace]);

  return (
    <MainLayout>
      <div className="d-flex align-items-center mb-4">
        <Button variant="link" onClick={onBack}>
          <IoMdArrowBack size={25} color="black" />
        </Button>
        <h3 className="mb-0 ml-2 mr-2">My Orders</h3>
      </div>

      <Row className="justify-content-center">
        {orders
          ?.filter((order) => order.status === "processing")
          ?.map((order) => (
            <Col key={order.id} lg={8}>
              <Order
                order={order}
                onComplete={() => onCompleteOrder(order.id)}
                currency={orders.currency}
              />
            </Col>
          ))}
      </Row>

      {/*Completed Orders*/}

      <Row className="justify-content-center">
        <Col lg={8}>
          <Card className="text-center mb-3">
            <Card.Body>
              <Card.Text>
                If you click on the button, you can see the completed orders for
                the current day
              </Card.Text>
              <Button
                onClick={handleToggle}
                aria-controls="completed-orders-collapse"
                aria-expanded={open}
                className="mb-4"
                variant="standard"
              >
                {open ? "Hide" : "Show"} Completed Orders
              </Button>
            </Card.Body>
          </Card>

          <Collapse in={open}>
            <div id="completed-orders-collapse">
              {orders
                ?.filter(
                  (order) =>
                    order.status === "completed" &&
                    new Date(order.created_at).setHours(0, 0, 0, 0) ===
                      getTodayDate().getTime()
                )
                ?.map((order) => (
                  <Card key={order.id} className="mb-3">
                    <Order
                      order={order}
                      onComplete={() => onCompleteOrder(order.id)}
                      currency="dsd"
                    />
                  </Card>
                ))}
            </div>
          </Collapse>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Orders;
