import { Button, Col, Form, Row, Card, Spinner } from "react-bootstrap";
import { React, useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import MainLayout from "../layouts/MainLayout";
import AuthContext from "../contexts/AuthContext";

const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password1, setPassword1] = useState("");

  const history = useHistory();
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (auth.session) {
      history.replace("/places");
    }
  });

  const onClick = () => {
    auth.register(email, password, password1, () => history.replace("/places"));
  };

  return (
    <MainLayout>
      <Row className="justify-content-center">
        <Col lg={6} md={8}>
          <Card>
            <Card.Body>
              <h3 className="my-4 text-center">
                <b>Create Account</b>
              </h3>

              <Form.Row>
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm Password"
                      value={password1}
                      onChange={(e) => setPassword1(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Form.Row>

              <div className="my-2">
                <Button
                  variant="standard"
                  block
                  onClick={onClick}
                  disabled={auth.loading}
                >
                  {auth.loading ? (
                    <Spinner
                      variant="standard"
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Sign Up"
                  )}
                </Button>
              </div>
              <div
                style={{ color: "gray", fontStyle: "italic", fontSize: "12px" }}
              >
                * The password must be at least 8 characters long, and include
                at least one lowercase letter, one uppercase letter, and one
                number.
              </div>
            </Card.Body>
            <div className="text-center mb-4">
              <Card.Link href="/login">
                Have an account? Go to Sign in
              </Card.Link>
            </div>
          </Card>
        </Col>
      </Row>
    </MainLayout>
  );
};

export default Register;
