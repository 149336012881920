import React, { useCallback, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { AiOutlineDelete } from "react-icons/ai";

import { removeImage, uploadImage } from "../apis";

const Dropzone = styled.div`
  border: 1px dashed #ced4d9;
  border-radius: 5px;
  color: #6c757d;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 142px;
  position: relative;

  img {
    height: 140px;
  }
`;

const TrashIcon = styled(AiOutlineDelete)`
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 20px;
  cursor: pointer;
  color: red;
`;

function ImageDropzone({ value, onChange }) {
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setLoading(true);
      uploadImage(acceptedFiles[0])
        .then((fileInfo) => {
          onChange(fileInfo.url);
          setFileName(fileInfo.name);
        })
        .finally(() => setLoading(false));
    },
    [onChange]
  );

  function removeFile(e) {
    e.stopPropagation();
    removeImage(fileName).then(() => {
      onChange("");
      setFileName(null);
    });
    // onChange("");
  }

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: "image/*",
  });

  return (
    <Dropzone {...getRootProps()}>
      <input {...getInputProps()} />
      {value ? (
        <>
          <img src={value} alt="Product thumbnail" />
          <TrashIcon onClick={removeFile} />
        </>
      ) : loading ? (
        <Spinner variant="standard" animation="border" role="status" />
      ) : (
        <span>Drag & drop file here, or click to select file</span>
      )}
    </Dropzone>
  );
}

export default ImageDropzone;
