import React, { useState } from "react";

import { Elements } from "@stripe/react-stripe-js";

import { Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import { createUnpaymentOrder } from "../apis";

const PaymentForm = ({ amount, items, onDone, color, currency }) => {
  const [loading, setLoading] = useState(false);

  const [comments, setComments] = useState("");

  const params = useParams();

  // const onSubmit = async (event) => {
  //   event.preventDefault();
  //   const { error, paymentMethod } = await stripe.createPaymentMethod({
  //     type: "card",
  //     card: elements.getElement(CardElement),
  //   });
  //
  //   if (!error) {
  //     setLoading(true);
  //     const json = await createPaymentIntent(
  //       {
  //         payment_method: paymentMethod,
  //         amount,
  //         place: params.id,
  //         table: params.table,
  //         detail: items,
  //       },
  //       auth.token
  //     );
  //
  //     if (json?.success) {
  //       toast(`Your order #${json.order} is processing`, { type: "success" });
  //       onDone();
  //       setLoading(false);
  //     } else if (json?.error) {
  //       toast(json.error, { type: "error" });
  //       setLoading(false);
  //     }
  //   }
  // };

  const onSubmitMakeOrder = async (event) => {
    event.preventDefault();
    setLoading(true);
    const json = await createUnpaymentOrder({
      amount,
      place_id: params.id,
      table: params.table,
      detail: items,
      currency: currency,
      comments: comments,
    });

    if (json?.success) {
      toast(`Your order #${json.id} is processing`, { type: "success" });
      onDone();
      setLoading(false);
    } else if (json?.error) {
      toast(json.error, { type: "error" });
      setLoading(false);
    }
  };

  const handleCommentsChange = (event) => {
    setComments(event.target.value);
  };

  return (
    <div>
      <Form onSubmit={onSubmitMakeOrder}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label>Comments</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            value={comments}
            onChange={handleCommentsChange}
          />
        </Form.Group>
        <Button
          variant="standard"
          style={{ backgroundColor: color }}
          className="mt-4"
          block
          type="submit"
          disabled={loading}
        >
          Make order
        </Button>
      </Form>

      {/*<Form.Group>*/}
      {/*  <Form onSubmit={onSubmit}>*/}
      {/*    <CardElement options={{ hidePostalCode: true }} />*/}
      {/*    <Button*/}
      {/*      variant="standard"*/}
      {/*      style={{ backgroundColor: color }}*/}
      {/*      className="mt-4"*/}
      {/*      block*/}
      {/*      type="submit"*/}
      {/*      disabled={loading}*/}
      {/*    >*/}
      {/*      {loading ? "Processing..." : "Pay"}*/}
      {/*    </Button>*/}
      {/*  </Form>*/}
      {/*</Form.Group>*/}
    </div>
  );
};

const StripeContext = (props) => (
  <Elements>
    <PaymentForm {...props} />
  </Elements>
);

export default StripeContext;
