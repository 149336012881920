import { IoMdArrowBack } from "react-icons/io";
import { AiOutlineDelete, AiOutlineQrcode } from "react-icons/ai";
import { BiEdit, BiUpArrowAlt, BiDownArrowAlt } from "react-icons/bi";
import { RiFileList3Line } from "react-icons/ri";
import { FiSettings } from "react-icons/fi";
import {
  Row,
  Col,
  Button,
  Modal,
  Popover,
  Form,
  OverlayTrigger,
} from "react-bootstrap";
import { useParams, useHistory } from "react-router-dom";
import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import {
  fetchPlace,
  removePlace,
  removeCategory,
  removeMenuItem,
  updatePlace,
  updateMenuItemPosition,
  updateCategoryPosition,
  updateCategory,
} from "../apis";

import AuthContext from "../contexts/AuthContext";
import MainLayout from "../layouts/MainLayout";
import MenuItemForm from "../containers/MenuItemForm";
import MenuItem from "../components/MenuItem";
import QRCodeModal from "../components/QRCodeModal";
import { toast } from "react-toastify";

const Panel = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.05);
`;

const Place = () => {
  const [place, setPlace] = useState({});
  const [menuItemFormShow, setMenuItemFormShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [qrCode, setQrCode] = useState(false);

  const showModal = () => setMenuItemFormShow(true);
  const hideModal = () => setMenuItemFormShow(false);

  const showQRModal = () => setQrCode(true);
  const hideQRModal = () => setQrCode(false);

  const { categories = [] } = place;

  const auth = useContext(AuthContext);
  const params = useParams();
  const history = useHistory();

  const [updatedCategory, setUpdatedCategory] = useState("");
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);

  const onBack = () => history.push("/places");

  const onFetchPlace = async () => {
    const json = await fetchPlace(params.id);

    if (json) {
      setPlace(json);
    }
  };

  const onRemovePlace = () => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removePlace(params.id).then(onBack);
    }
  };

  const onRemoveCategory = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeCategory(id).then(onFetchPlace);
    }
  };

  const onUpdateCategory = async (id) => {
    const json = await updateCategory(id, updatedCategory);

    if (json) {
      toast(`Category was changed.`, { type: "success" });
      onFetchPlace();
    }
  };

  const onRemoveMenuItem = (id) => {
    const c = window.confirm("Are you sure?");
    if (c) {
      removeMenuItem(id).then(onFetchPlace);
    }
  };

  const onUpdatePlace = (tables) => {
    updatePlace(place.id, { number_of_tables: tables }).then((json) => {
      if (json) {
        setPlace(json);
      }
    });
  };

  const moveMenuItemUp = async (item, menuItems) => {
    if (item.position === 0) return;
    const newPosition = item.position - 1;
    const itemToMoveDown = menuItems.find(
      (menuItem) => menuItem.position === newPosition
    );
    await updateMenuItemPosition(item.id, newPosition);
    await updateMenuItemPosition(itemToMoveDown.id, item.position);
    onFetchPlace();
  };

  const moveMenuItemDown = async (item, menuItems) => {
    const lastPosition = menuItems.length - 1;
    if (item.position === lastPosition) return;
    const newPosition = item.position + 1;
    const itemToMoveUp = menuItems.find(
      (menuItem) => menuItem.position === newPosition
    );
    await updateMenuItemPosition(item.id, newPosition);
    await updateMenuItemPosition(itemToMoveUp.id, item.position);
    onFetchPlace();
  };

  const moveCategoryUp = async (category, categories) => {
    if (category.position === 0) return;
    const newPosition = category.position - 1;
    const categoryToMoveDown = categories.find(
      (cat) => cat.position === newPosition
    );
    await updateCategoryPosition(category.id, newPosition);
    if (categoryToMoveDown) {
      await updateCategoryPosition(
        categoryToMoveDown.id,
        category.position,
        auth.token
      );
    }
    onFetchPlace();
  };

  const moveCategoryDown = async (category, categories) => {
    const lastPosition = categories.length - 1;
    if (category.position === lastPosition) return;
    const newPosition = category.position + 1;
    const categoryToMoveUp = categories.find(
      (cat) => cat.position === newPosition
    );
    await updateCategoryPosition(category.id, newPosition);
    if (categoryToMoveUp) {
      await updateCategoryPosition(categoryToMoveUp.id, category.position);
    }
    onFetchPlace();
  };

  useEffect(() => {
    onFetchPlace();
  });

  return (
    <MainLayout>
      <Row>
        <Col lg={12}>
          <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <Button variant="link" onClick={onBack}>
                <IoMdArrowBack size={25} color="black" />
              </Button>
              <h3 className="mb-0 ml-2 mr-2">{place.name}</h3>

              <Button variant="link" onClick={onRemovePlace}>
                <AiOutlineDelete size={25} color="red" />
              </Button>
            </div>

            <Button variant="link" onClick={showQRModal}>
              <AiOutlineQrcode size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/orders`}>
              <RiFileList3Line size={25} />
            </Button>
            <Button variant="link" href={`/places/${params.id}/settings`}>
              <FiSettings size={25} />
            </Button>
          </div>
        </Col>

        <Col md={4}>
          <Panel>
            <MenuItemForm place={place} onDone={onFetchPlace} />
          </Panel>
        </Col>

        <Col md={8}>
          {categories
            .sort((a, b) => a.position - b.position)
            .map((category, index, categories) => (
              <div key={category.id} className="mb-5">
                <div className="d-flex align-items-center mb-4">
                  <h4 className="mb-0 mr-2">
                    <b>{category.name}</b>
                  </h4>
                  <Button
                    variant="link"
                    className="button-spacing"
                    onClick={() => moveCategoryUp(category, categories)}
                    disabled={index === 0}
                  >
                    <BiUpArrowAlt size={25} color="black" />
                  </Button>
                  <Button
                    variant="link"
                    className="button-spacing"
                    onClick={() => moveCategoryDown(category, categories)}
                    disabled={index === categories.length - 1}
                  >
                    <BiDownArrowAlt size={25} color="black" />
                  </Button>

                  <OverlayTrigger
                    show={selectedCategoryId === category.id}
                    trigger="click"
                    placement="bottom"
                    rootClose
                    onHide={() => setSelectedCategoryId(null)}
                    overlay={
                      <Popover id="popover-contained">
                        <Popover.Title as="h3">Category</Popover.Title>
                        <Popover.Content>
                          <Form.Group>
                            <Form.Control
                              type="text"
                              placeholder="Category Name"
                              value={updatedCategory || category.name}
                              onChange={(e) =>
                                setUpdatedCategory(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Button
                            variant="standard"
                            block
                            onClick={() => {
                              onUpdateCategory(category.id);
                              setSelectedCategoryId(null);
                            }}
                          >
                            Update Category
                          </Button>
                        </Popover.Content>
                      </Popover>
                    }
                  >
                    <Button
                      variant="link"
                      onClick={() =>
                        selectedCategoryId === category.id
                          ? setSelectedCategoryId(null)
                          : setSelectedCategoryId(category.id)
                      }
                    >
                      <BiEdit size={25} />
                    </Button>
                  </OverlayTrigger>

                  <Button
                    variant="link"
                    onClick={() => onRemoveCategory(category.id)}
                  >
                    <AiOutlineDelete size={25} color="red" />
                  </Button>
                </div>
                {category.menu_items
                  //.sort((a, b) => a.position - b.position)
                  .map((item, index, items) => (
                    <MenuItem
                      key={item.id}
                      item={item}
                      currency={place.currency}
                      onEdit={() => {
                        setSelectedItem(item);
                        showModal();
                      }}
                      onRemove={() => onRemoveMenuItem(item.id)}
                      onUp={() => moveMenuItemUp(item, category.menu_items)}
                      onDown={() => moveMenuItemDown(item, category.menu_items)}
                      isFirst={index === 0}
                      isLast={index === items.length - 1}
                      size={place.size}
                      font={place.font}
                    />
                  ))}
              </div>
            ))}
        </Col>
      </Row>

      <Modal show={menuItemFormShow} onHide={hideModal} centered>
        <Modal.Body>
          <h4 className="text-center">Menu Item</h4>
          <MenuItemForm
            place={place}
            onDone={() => {
              onFetchPlace();
              hideModal();
            }}
            item={selectedItem}
          />
        </Modal.Body>
      </Modal>

      <QRCodeModal
        show={qrCode}
        onHide={hideQRModal}
        place={place}
        centered
        onUpdatePlace={onUpdatePlace}
      />
    </MainLayout>
  );
};

export default Place;
