import React, { useState } from "react";
import styled from "styled-components";
import MenuItem from "./MenuItem";
import { BiCaretDownSquare, BiCaretUpSquare } from "react-icons/bi";

// import { SmallCard, MediumCard, LargeCard } from "./DishCards";

const Place = styled.div`
  text-align: center;
  img {
    border-radius: 5px;
    margin-bottom: 20px;
  }
`;

const Container = styled.div`
  b,
  p {
    ${({ font }) => font && `font-family: ${font};`}
  }
`;

const CategoryTitle = styled.div`
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
`;

const MenuList = ({
  place,
  shoppingCart = {},
  onOrder,
  font = "",
  color = "",
  currency,
  grouping,
  size,
}) => {
  const [expandedCategories, setExpandedCategories] = useState({});

  const toggleCategory = (categoryId) => {
    if (grouping) {
      setExpandedCategories((prevState) => ({
        ...prevState,
        [categoryId]: !prevState[categoryId],
      }));
    }
  };

  return (
    <Container font={font}>
      <Place>
        <img
          src={place.image}
          alt={`Thumbnail of ${place.name}`}
          width={100}
          height={100}
        />
        <h3>
          <b>{place.name}</b>
        </h3>
      </Place>
      {place?.categories
        ?.filter(
          (category) => category.menu_items.filter((i) => i.is_available).length
        )
        .map((category) => (
          <div key={category.id} className="mt-5">
            <h4 className="mb-4">
              <CategoryTitle onClick={() => toggleCategory(category.id)}>
                <b>{category.name}</b>
                {grouping && expandedCategories[category.id] ? (
                  <BiCaretUpSquare style={{ marginLeft: "8px" }} />
                ) : (
                  grouping && (
                    <BiCaretDownSquare style={{ marginLeft: "8px" }} />
                  )
                )}
              </CategoryTitle>
            </h4>
            {(!grouping || expandedCategories[category.id]) &&
              category.menu_items
                .sort((a, b) => a.position - b.position)
                .filter((item) => item.is_available)
                .map((item) => (
                  <MenuItem
                    key={item.id}
                    item={{
                      ...item,
                      quantity: shoppingCart[item.id]?.quantity,
                    }}
                    onOrder={onOrder}
                    color={color}
                    currency={currency}
                    size={size}
                  />
                ))}

            {/*{(!grouping || expandedCategories[category.id]) &&*/}
            {/*  category.menu_items*/}
            {/*    .sort((a, b) => a.position - b.position)*/}
            {/*    .filter((item) => item.is_available)*/}
            {/*    .map((item) => {*/}
            {/*      const cardProps = {*/}
            {/*        item: {*/}
            {/*          ...item,*/}
            {/*          quantity: shoppingCart[item.id]?.quantity,*/}
            {/*        },*/}
            {/*        onOrder,*/}
            {/*        color,*/}
            {/*        currency,*/}
            {/*      };*/}

            {/*      if (size === "S") {*/}
            {/*        return <SmallCard key={item.id} {...cardProps} />;*/}
            {/*      } else if (size === "M") {*/}
            {/*        return <MediumCard key={item.id} {...cardProps} />;*/}
            {/*      } else {*/}
            {/*        return <LargeCard key={item.id} {...cardProps} />;*/}
            {/*      }*/}
            {/*    })}*/}
          </div>
        ))}
    </Container>
  );
};

export default MenuList;
